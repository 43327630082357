import { useState, useEffect } from "react";
import sanityClient from "../../client";
import { useLocation } from "react-router-dom";

export default function SingleAboutUs() {
  const [affiliate, setAffiliate] = useState(null);
  let location = useLocation();
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "affiliate"]{
      name,
      slug,
      link,
      publishedAt,
      bio,
      image{
        asset->{
          _id,
          url
        },
      },
    }`
      )
      .then((data) => {
        setAffiliate(
          data.find((each) => each.slug.current === location.pathname.slice(10))
        );
      })
      .catch(console.error);
  }, [location.pathname]);

  return (
    <div className="bg-aajc-lightorange">
      <div className="max-w-6xl mx-auto">
        {affiliate && (
          <div className="flex justify-between gap-10 items-center flex-col-reverse md:flex-row">
            <section className="flex flex-col justify-center items-center gap-10 p-5 md:px-0">
              <h1 className="text-2xl font-bold tracking-tighter">
                {affiliate.name}
              </h1>
              <p className="leading-loose">{affiliate.bio}</p>
              <p className="text-sm font-semibold">
                To learn more about our work and donate, please visit our{" "}
                <a
                  href={affiliate.link}
                  className="underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  main site
                </a>
                .
              </p>
            </section>
            <img
              src={affiliate.image.asset.url}
              alt=""
              className="object-cover md:w-1/2"
            />
          </div>
        )}
      </div>

       {location.pathname.slice(10) == "southern-california" && (
        <div className="max-w-6xl mx-auto">
          
            <div className="flex justify-between gap-10 items-center flex-col-reverse md:flex-row">
              <section className="flex flex-col justify-center items-center gap-10 p-5 md:px-0">
                <p className="leading-loose">We want to ensure you’re informed and prepared. Many of you may be feeling uncertain about the changes the new administration is bringing. Now is the time to get prepared, stay vigilant and fight for your rights. Find more resources for safety planning in English and multiple Asian languages on AJSOCAL's website. <strong><a href="https://ajsocal.org/immigration/" className="underline text-sm"><span>Immigration Services and Resources</span></a></strong></p>
              </section>
              <img
                src="/KYR_Affiliation_Graphic.jpg"
                alt=""
                className="object-cover md:w-1/2"
              />
            </div>
        
        </div>
      )}

    </div>
  );
}
